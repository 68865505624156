export const TRANSLATIONS_EN = {
	language: "Language",

	assessment: {
		score: {
			percentile: {
				name: "National Percentile",
				description: "the score compared to individuals of the same age. It ranges between 0 and 100 with 50% being average."
			}
		},
		constructs: {
			agreeableness: {
				name: "Agreeableness",
				description: "The extent to which a person is cooperative, empathetic, and compassionate towards others",
				scaleLeft: {
					name: "Contrary",
					description: "Characterized by a strong desire to win or be the best; often involves comparing oneself against others, with an emphasis on achieving superiority or success at the expense of others."
				},
				scaleRight: {
					name: "Cooperative",
					description: "Showing sympathy and concern for the sufferings or misfortunes of others; often involves a desire to help or alleviate the pain and hardships of others."
				}
			},
			conscientiousness: {
				name: "Conscientiousness",
				description: "Characterized by diligence, carefulness, thoroughness, self-discipline, and the desire to do tasks well.",
				scaleLeft: {
					name: "Careless",
					description: " Exhibiting a lack of attention to detail or thoughtfulness in tasks or responsibilities, often leading to mistakes or oversight. Careless behavior is typically marked by a disregard for accuracy and a failure to take the necessary care in activities."
				},
				scaleRight: {
					name: "Diligent",
					description: "Demonstrating careful and persistent work or effort. Diligent individuals are thorough, meticulous, and consistently attentive to the task at hand, often leading to high-quality outcomes."
				}
			},
			"emotion-regulation": {
				name: "Emotion Regulation",
				description: "An individual's ability to effectively manage and respond to their emotional experiences.",
				scaleLeft: {
					name: "Reactive",
					description: "Characterized by immediate, often emotional responses to situations or stimuli, without significant deliberation or control over one's emotions."
				},
				scaleRight: {
					name: "Resilient",
					description: "The ability to adapt well and recover quickly from stress, adversity, or trauma, often involving effective coping mechanisms and emotional flexibility."
				}
			},
			engagement: {
				name: "Engagement",
				description: "the level of a person's psychological investment and active involvement in activities, tasks, or interactions.",
				scaleLeft: {
					name: "Apathetic",
					description: "Showing or feeling no interest, enthusiasm, or concern; a lack of engagement or emotional investment in activities or interactions."
				},
				scaleRight: {
					name: "Enthusiastic",
					description: "Exhibiting intense and eager enjoyment, interest, or approval; being highly engaged and energetically involved in activities or tasks."
				}
			},
			extraversion: {
				name: "Extraversion / Introversion",
				description: "The extent to which a person is outgoing and energized by social interactions (Extraversion) versus reserved and energized by solitary activities (Introversion).",
				scaleLeft: {
					name: "Introverted",
					description: "Characterized by a preference for solitary activities and a tendency to be reserved and reflective; introverts often recharge by spending time alone and may feel drained by extensive social interaction."
				},
				scaleRight: {
					name: "Extroverted",
					description: "Exhibiting outgoing, energetic behavior, and enjoying being around and interacting with others; extraverts are often energized by social gatherings and external stimuli."
				}
			},
			grit: {
				name: "Grit",
				description: "The perseverance and passion for long-term goals, encompassing an individual's persistence of effort and consistency of interest over time.",
				scaleLeft: {
					name: "Inconsistent",
					description: "Characterized by a lack of steadiness and regularity in actions or effort, often resulting in a failure to follow through on commitments or to maintain focus on long-term goals."
				},
				scaleRight: {
					name: "Persistent",
					description: "Exhibiting a firmness of purpose and a steadfast adherence to a course of action, often indicative of a strong commitment to achieving long-term objectives despite challenges and setbacks."
				}
			},
			growth: {
				name: "Growth Mindset",
				description: "The belief that abilities and intelligence can be developed through dedication, hard work, and perseverance, emphasizing learning and resilience in the face of challenges.",
				scaleLeft: {
					name: "Fixed",
					description: "The belief that abilities, intelligence, and talents are fixed traits that cannot be significantly developed or changed. Individuals with a fixed mindset may avoid challenges and feel threatened by the success of others."
				},
				scaleRight: {
					name: "Adaptive",
					description: "Characterized by flexibility, a willingness to learn and adjust to new circumstances or information, and the ability to thrive in changing environments. An adaptive mindset aligns with the principles of a growth mindset, focusing on development and improvement."
				}
			},
			"negative-feelings": {
				name: "Negative Feelings",
				description: "The extent to which a person experiences and is affected by emotions such as sadness, anger, anxiety, and other unpleasant emotions.",
				scaleLeft: {
					name: "Unaffected",
					description: "Characterized by a lack of emotional disturbance or reactivity to negative stimuli; individuals who are unaffected maintain emotional stability and are not easily swayed by adverse feelings."
				},
				scaleRight: {
					name: "Sensitive",
					description: "Demonstrating a heightened responsiveness to negative emotions; sensitive individuals may experience intense reactions to unpleasant feelings and may be more deeply impacted by negative experiences."
				}
			},
			neuroticism: {
				name: "Neuroticism",
				description: "Signifies a higher tendency towards experiencing negative emotions like anxiety, sadness, and mood swings.",
				scaleLeft: {
					name: "Stable",
					description: "Indicative of emotional steadiness and resilience; individuals who are stable typically experience fewer mood swings and are less likely to feel stressed or upset in challenging situations."
				},
				scaleRight: {
					name: "Anxious",
					description: "Characterized by frequent feelings of worry, nervousness, or unease, often about imminent events or something with an uncertain outcome. People with high levels of neuroticism may be more prone to experiencing such emotions."
				}
			},
			openness: {
				name: "Openness",
				description: "Reflects a person's willingness to consider new ideas, experiences, and values, and their preference for creativity and variety.",
				scaleLeft: {
					name: "Conventional",
					description: "Characterized by a preference for familiarity, tradition, and the established way of doing things. Individuals with a conventional approach often prefer routine and may be resistant to change."
				},
				scaleRight: {
					name: "Creative",
					description: "Demonstrating creativity, originality, and a readiness to explore new and unconventional ideas or methods. Inventive individuals are often open to diverse experiences and ways of thinking."
				}
			},
			"positive-feelings": {
				name: "Positive Feelings",
				description: "The frequency and intensity with which a person experiences pleasant emotions, such as happiness, joy, contentment, and love.",
				scaleLeft: {
					name: "Reserved",
					description: "Characterized by a more subdued or controlled expression of emotions, often implying a lesser frequency or intensity of experiencing overtly positive feelings."
				},
				scaleRight: {
					name: "Upbeat",
					description: " Exhibiting high levels of enthusiasm, energy, and joy; individuals who are exuberant often feel and express positive emotions intensely and vividly."
				}
			},
			reflection: {
				name: "Reflection",
				description: "The ability or tendency to question and analyze one's own thought processes and responses, especially when faced with problems or decisions.",
				scaleLeft: {
					name: "Reactive",
					description: "Characterized by a tendency to act quickly and without sufficient thought or deliberation, often leading to hasty and rash decisions without considering potential consequences."
				},
				scaleRight: {
					name: "Reflective",
					description: "Demonstrating thoughtful consideration and examination of one's own beliefs, decisions, and responses. Reflective individuals tend to analyze situations thoroughly and are more likely to engage in introspection."
				}
			},
			"self-control": {
				name: "Self Control",
				description: "The ability to regulate one's emotions, thoughts, and behavior in the face of temptations and impulses.",
				scaleLeft: {
					name: "Impulsive",
					description: "Characterized by acting on desires and urges rather quickly without adequate forethought or consideration of consequences. Impulsive individuals often find it challenging to resist immediate temptations."
				},
				scaleRight: {
					name: "Disciplined",
					description: "Exhibiting a high level of self-restraint and the ability to resist short-term temptations in order to meet long-term goals. Disciplined individuals are typically consistent in their habits and decision-making processes."
				}
			},
			stress: {
				name: "Stress Management",
				description: "Stress management in the involves using techniques and strategies to reduce and control stress, enhancing well-being and performance.",
				scaleLeft: {
					name: "Resilient",
					description: "Exhibiting the ability to quickly recover from stress, demonstrating adaptability and mental toughness in the face of difficulties. Resilient individuals can effectively manage stress and bounce back from challenges."
				},
				scaleRight: {
					name: "Easily overwhelmed",
					description: "Feeling inundated and unable to cope effectively with stress, often resulting in significant disruption to emotional and physical wellbeing. Overwhelmed individuals might experience heightened anxiety and have difficulty managing stressors."
				}
			},
			"time-mgmt": {
				name: "Time Management",
				description: "The skill of organizing and planning how to divide one's time between specific activities to increase efficiency and productivity.",
				scaleLeft: {
					name: "Disorganized",
					description: "Demonstrating a lack of structure or planning in managing time, often leading to inefficiency, missed deadlines, and an inability to prioritize tasks effectively."
				},
				scaleRight: {
					name: "Efficient",
					description: "Characterized by a high level of organization and the ability to use time productively and judiciously, often resulting in the successful completion of tasks in a timely manner."
				}
			}
		}
	},
	reports: {
		education: {
			family: {
				name: "Overview Report",
				greeting: "Dear Family Member(s)",
				overviewEf: "Executive Function (EF) is a set of brain-based skills that helps people to control their own behavior \
					and direct it toward goals instead of doing what is automatic or easiest. EF skills are important for \
					learning in classroom settings, getting along with other participants, and making good decisions. The three \
					skills of EF include working memory, cognitive flexibility, and impulse control. These skill can be \
					measured, and reinforced through reflection and practice.",
				overviewGame: "You participated in a game-like assessment of their Executive Function skills called EFgo™. This game \
				  asks participants to match and sort pictures based on features such as size, color, and shape. At each level, \
					you were asked to follow one rule, and then switch and follow a different rule. To play this game, participants \
					need to pay attention, remember the rules, and think flexibly.",
				overviewResults: "Following are the results from the most recent assessment and how the scores align with age expectation compared to our national norms of over 5,000 participants.",
				activities: {
					overview: "The following are some ideas for activities to support the growth of EF skills:",
					option1: "Practice movement games that require participants to calm their bodies, such as walking in a perfectly straight line, doing simple yoga poses, or “freezing” in the middle of an action.",
					option2: "Try switching rules to games participants are comfortable playing (e.g., playing opposite Simon Says \
						where you DON’T do the actions that Simon Says, or doing the BINGO song where you start with no \
						letters and add them in, instead of taking them out).",
					option3: "Have your participant share and reflect on what they did in school during the day. Verbal processing and reflection are key to EF growth.",
				},
				learnMore: "To learn more, check out Reflective Performance's resources"
			}
		},
	},

	forms: {
		common: {
			selectMonth: "Select Month...",
			enter: "Enter",
			months: {
				january: "January",
				february: "February",
				march: "March",
				april: "April",
				may: "May",
				june: "June",
				july: "July",
				august: "August",
				september: "September",
				october: "October",
				november: "November",
				december: "December"
			},
		},
		participant: {
			studentId: "Participant ID",
			email: "Email",
			name: "Name",
			firstName: "First Name",
			lastName: "Last Name",
			selectGender: "Select Gender...",
			gender: "Gender",
			genderOptions: {
				female: "Female",
				male: "Male",
				other: "Gender diverse",
				private: "Prefer not to say"
			},
			birthMonthYear: "Birth Month / Year",
			birthYear: "Birth Year",
			selectBirthMonth: "Select Birth Month...",
			raceAndEthnicity: "Race / Ethnicity",
			add: {
				title: "Need to be added?",
				message: "Add individual to be assessed",
				button: "Continue"
			},
			edit: {
				title: "Update information of individual to be assessed",
				button: "Continue"
			},
		},
	},
	zoneMapping: {
		strengths: {
			label: "Strengths",
			reflection: {
				label: "Reflection",
				description: "Reflection is when we pause to consider the situation and become self-aware.",
				crisis: "Acting decisively in crisis",
				content: "Being able to say \"good enough\"",
				reflective: "Being reflective",
				strategic: "Being strategic, planful",
				vision: "Seeing the big picture",
			},
			workingMemory: {
				label: "Working Memory",
				description: "Working Memory is the ability to keep information in mind.",
				prioritization: "Prioritizing immediate demands",
				memory: "Holding information in mind",
			},
			inhibitoryControl: {
				label: "Impulse Control",
				description: "Impulse Control is being able to stay focused and resist impulsive behavior.",
				ideas: "Generating lots of ideas quickly",
				persistent: "Persisting when passionate",
				riskTaking: "Willing to take risks",
				conscientious: "Being conscientious",
				longTerm: "Working toward long-term goals",
				focus: "Staying focused when there are distractions",
				waiting: "Waiting for your turn",
			},
			cognitiveFlexibility: {
				label: "Cognitive Flexibility",
				description: "Flexible Thinking is the ability to think about something in multiple ways or from a different perspective.",
				outsideTheBox: "Thinking outside the box",
				multitasking: "Multi-tasking",
				flexibility: "Switching flexibly to adapt to changing circumstances and demands",
				perspective: "Taking other people's perspectives",
			}
		},
		challenges: {
			label: "Challenges",
			reflection: {
				label: "Reflection",
				vision: "Not seeing the big picture",
				crisis: "Being slow to act in a crisis",
				content: "Not being able to say \"good enough\"",
				perfection: "Being prone to perfectionism",
			},
			workingMemory: {
				label: "Working Memory",
				longTerm: "Giving up on longer-term goals",
				forgetful: "Forgetting what you were working on",
				prioritization: "Difficulty prioritizing immediate demands",
				estimation: "Being unrealistic about time needed for tasks",
			},
			inhibitoryControl: {
				label: "Impulse Control",
				impulsive: "Being impulsive",
				rushing: "Rushing to complete tasks",
				distracted: "Being easily distracted",
				frustrated: "Giving up when frustrated",
				riskTaking: "Being afraid to take risks",
				waiting: "Waiting for your turn",
				procrastinating: "Procrastinating",
			},
			cognitiveFlexibility: {
				label: "Cognitive Flexibility",
				inflexible: "Being inflexible when things change",
				creativity: "Difficulty being creative",
				overwhelmed: "Being overwhelmed by too many options",
				decision: "Being slow to make decisions",
			}
		},
		improvement: {
			label: 'Improvement Tips & Activities',
			description: 'Below are practical ways to improve your EF performance.',
			reflection: {
				label: "Reflection"
			},
			workingMemory: {
				label: "Working Memory"
			},
			inhibitoryControl: {
				label: "Impulse Control"
			},
			cognitiveFlexibility: {
				label: "Flexible Thinking",
				description: "Flexible Thinking is the ability to think about something in multiple ways or from a different perspective."
			}
		}
	},
	improvement: {
		tips: {
			defineMeeting: {
				label: "Define Meeting",
				description: "Define meeting purpose and stay on point",
			},
			thinkTime: {
				label: "Think Time",
				description: "Allow think time in meetings",
			},
			dontRush: {
				label: "Don't Rush",
				description: "Do not rush major decisions",
			},
			reflectionTime: {
				label: "Reflection Time",
				description: "Give time for reflection and embrace its value",
			},
			contribute: {
				label: "Contribution",
				description: "Encourage everyone to contribute",
			},
			faceToface: {
				label: "Face to Face",
				description: "Require face-to-face meetings for strategic decisions",
			},
			createRoutines: {
				label: "Create structured routines",
				description: "Establish a daily schedule that includes class times, practice sessions, study periods, and rest. You can hand write it or use digitial tools such as calendar apps to set reminders and alarms.",
			},
			simplifyTasks: {
				label: "Simplify tasks",
				description: "Break down assignments and tasks into smaller, manageable steps. This helps you focus on completing one task at a time to avoid feeling overwhlemed.",
			},
			visualAids: {
				label: "Utilize visual aids",
				description: "Use checklists, stick notes, and visual planners to keep track of tasks and deadlines. In addition to this, color coding tasks based on the level of priority or category can be beneficial too.",
			},
			seekSupport: {
				label: "Seek support",
				description: "Work with academic advisors, tutors, or mentors to develop personalized strategies. You can join study groups or find a study buddy to hold yourself accountable.",
			},
			mindfulness: {
				label: "Practice mindfulness",
				description: "Engage in minfulness or relaxation techniques to improve focus and reduce stress. You can also incorporate short meditation sessions into your daily routine",
			},
			timeManagement: {
				label: "Enhance time management",
				description: "Prioritize tasks by thinking what is urgent vs important to focus on what matters the most. For academic work and athletic training, you can allocate specific time blocks.",
			},
			selfMonitor: {
				label: "Strengthen self-monitoring",
				description: "On a daily basis for 5-10 minutes, reflect on your performance and set goals for improvement. You can keep a journal to track progress and identify patterns in how effective your production is",
			},
			balanceAndFlexibility: {
				label: "Balance and flexibility",
				description: "Learn to adapt to changes in your schedule and maintain a healthy work-life balance. By changing up for your daily routine with various activities, you can increase your engagement levels. For example, one day you workout and another day you choose to read. This way you are keeping stimulation levels balanced and increasing different areas of focus in your brain.",
			},
			maximizeEfficiency: {
				label: "Maximize efficiency",
				description: "Identify peak performance times and schedule high-priority tasks during these periods. For instance, if you are more productive in the morning, work on the most relevant tasks. ",
			},
			cultivateLeadershipSkills: {
				label: "Cultivate leadership skills",
				description: "Take on leadership roles within teams or academic groups. Your cognitive capabilities help you see and understand things others may not. By taking on these roles, you are challenging your mind, while also helping others."
			},
			focusOnPersonalGrowth: {
				label: "Focus on personal growth",
				description: "Engage in activities that challenge your cognitive and emotional skills, such as learning a new language. For sport-specific, you could learn a new methodology within your sport."
			},
			maintainResilienceAndAdaptability: {
				label: "Maintain resilience and adaptability",
				description: "Build resilience through mental training techniques such as visualization and positive self-talk. You can adapt easier to your environment by incorporating new challenges and learning opportunities."
			},
			listenBeforeSpeaking: {
				label: "Listen Before Speaking",
				description: "Honestly listen before speaking."
			},
			listen5050: {
				label: "Listen 50/50",
				description: "50 / 50 rule of listening and speaking."
			},
			reflectOnConversation: {
				label: "Reflect on conversation",
				description: "Reflect seriously on what others say."
			},
			askQuestions: {
				label: "Ask Questions",
				description: "Ask Questions."
			},
			restTheMind: {
				label: "Rest the mind",
				description: "Rest the mind by un-plugging, taking work breaks, and exercising."
			},
			paceYourself: {
				label: "Pace yourself",
				description: "Pace yourself mentally as well as physically."
			},
			quietPlace: {
				label: "Quiet Place",
				description: "Find a quiet place (be alone, turn off technologies)."
			},
			clearTheMind: {
				label: "Clear the mind",
				description: "Clear the mind (Take a run or walk; turn off smartphones)."
			},
			sleep: {
				label: "Sleep",
				description: "Ensure proper sleep / rest (Attention Aid)."
			},
			noStimulants: {
				label: "No Stimulants",
				description: "Avoid mind-altering stimulants (Concentration Aid)."
			},
			takeNotes: {
				label: "Take Notes",
				description: "Take notes (Memory Aid)."
			},
			readDirections: {
				label: "Read Directions",
				description: "Read directions carefully (Impulse Control)."
			},
			establishPace: {
				label: "Establish Pace",
				description: "Establish a pace (Cognitive Flexibility)."
			},
			reflectionForQuestions: {
				label: "Reflection for Questions",
				description: "Take a moment to answer any question (Reflection)."
			},
			circleBack: {
				label: "Circle back",
				description: "Defer answering a difficult question and circle-back (Reflection)."
			},
			readAloud: {
				label: "Read aloud",
				description: "Read the question quietly out loud (Comprehension Aid)."
			},
			writeDown: {
				label: "Write down",
				description: "Write down difficult questions (Mind-Body Reinforcer)."
			},
			mindfulnessPractices: {
				label: "Mindfulness practices",
				description: "Incorporate meditation to become more self-aware and reduce stress."
			},
		},

		activities: {
			rearrangeYourGearBag: {
				label: "Rearrange your gear bag",
				description: "If you arrange your gear bag a specific way, change the arrangement a couple of times a week."
			},
			spend15MinutesNonDominant: {
				label: "Spend 15 minutes shooting with your non-dominant arm or taking off on your non-dominant foot",
				description: "Take the time to focus playing with your non-dominant hand. Also start running with your non-dominant foot."
			},
			watchingFilmAndDiscussing: {
				label: "Watching film and actively discuss it",
				description: "By watching and discussing different viewpoints, you can gain various perspectives and become more adaptive in your environment."
			},
			scenarioTraining: {
				label: "Scenario training",
				description: "By running set pieces or how to set picks, scenario-based training stimulates game situations that require quick decision-making."
			},
			focusDrills: {
				label: "Focus drills",
				description: "Run drills that require sustained attention, like reaction time exercises and mindfulness meditation."
			},
			runOppositeDirection: {
				label: "Where possible (and where it is safe to do so) run the opposite direction on the track",
				description: "Instead of running in the typical direction, run the opposite direction."
			},
			mindfulnessPractices: {
				label: "Mindfulness practices",
				description: "Incorporate meditation to become more self-aware and reduce stress."
			},
			taskBreakdown: {
				label: "Task breakdown",
				description: "Break down tasks into smaller, manageable steps and use checklists to keep track of progress."
			},
			askCoachForPracticePlan: {
				label: "Ask your coach for a practice plan prior to practice and set small goals for each drill or exercise",
				description: "Asking for the practice plan primes your body for the upcoming tasks. When you also set intentions, you can be more mindful, focused, and in tune with your body. This overall strengthens your mind-body connection."
			},
			decisionMakingDrills: {
				label: "Decision making drills",
				description: "Practice drills that require quick decisions, such as changing strategies mid-drill based on new information or unexpected changes. This can desensitize you from the stress of this happening in a game, helping you react quicker and better in a game."
			},
			setConsciousIntention: {
				label: "Set a conscious intention for one single task before practice or a game",
				description: "Before your athletic activity, choose one thing you want to accomplish."
			},
			riskTakingExercises: {
				label: "Risk-taking exercises",
				description: "In controlled environments, start practicing taking risks through scrimmages or new drills that take you out of your comfort zone."
			},
			pauseAndCountToThree: {
				label: "Pause and count to 3 before responding after a referee call you don't agree with",
				description: "After hearing a call you don't agree with, take a few seconds to process it and consider why it was called, then approach the referee."
			},
			beMentallyInvolved: {
				label: "Be mentally involved",
				description: "Whether you are on the field or on the sideline, you should be following each play and evaluating situations. You can decrease stress and anxiety by following the situations on the field so when you are called in, you are reactive and can contribute how you want to."
			},
			goalSettingAndReview: {
				label: "Goal setting and review",
				description: "Create goals for yourself such as SMART (specific, measurable, achievable, relevant, time-bound) goals that can be done within a specific amount of time. This can create mindfulness and give you extra structure or direction when playing."
			},
			developStrengthsWeaknessesChecklist: {
				label: "Develop a list of strengths and weaknesses along with a checklist to keep track of your progress",
				description: "Create a list of your strengths and weaknesses with a checklist to ensure you are actively working on yourself and holding yourself accountable."
			},
			coolDownBeforeLeaving: {
				label: "Do a cool down exercise (lap or 25 pushups) before leaving practice",
				description: "Take the time after practice to cool down and settle your mind. This helps bridge the gap between the athletic headspace and your day-to-day headspace."
			},
			takeOwnershipWithCoaches: {
				label: "Take ownership and be proactive with coaches",
				description: "Take responsibility for your actions on the field and be ready and prepared to listen to your coach. Make sure that you AND your teammates are receptive to your coaches' instructions."
			},
			selfReflection: {
				label: "Journaling and self-reflection",
				description: "Take 10-15 minutes after practice and/or a game to evaluate yourself on 3 things you think went well, 3 things you could do better, and how you are going to improve those 3 things."
			},
			stayOffPhone: {
				label: "Stay off your phone one hour after practice, workout, or game",
				description: "By staying off your phone, you can process what your body has learned and done more efficiently. It also allows your brain breathing space before you stimulate it again."
			},
			prePracticeRoutine: {
				label: "Develop a pre-practice and pre-game routine over time",
				description: "With time, create a routine that helps you get into the mindset. Try different things such as listening to music in the locker room, doing visualization scenarios, meditation, creating personal goals, etc."
			},
			studyingPlays: {
				label: "Studying plays",
				description: "Create flashcards or a playbook to help recognize plays and execute them."
			},
			timeManagementWorkshops: {
				label: "Time management workshops",
				description: "By attending these workshops, you can begin to develop time management skills that help balance academic and athletic responsibilities."
			}
		}
	},
}